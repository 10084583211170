import React from "react";
import { Tabs,Button,Badge ,Modal } from 'antd';
import {RightOutlined} from '@ant-design/icons';
import * as echarts from 'echarts'
import Charts from './charts'
import Track from './tracking'
import Customize from './customize'
import { SketchPicker } from 'react-color';
import '../style/ant.css';
import '../style/app.less';
import fetch from '../fetch/axios'

let appId = window.id || 429
let shop = window.shop || '10-hz-ts-21-mutao-jingtian.myshopify.com'
let Url = process.env.URL

const { TabPane } = Tabs;

class Index extends React.Component {
  constructor(props){
  super(props);
  this.state = {
    nav_list:[
      {name:'Dashboard',value:'1',navColor:true},
      {name:'Tracking Number',value:'2',navColor:false},
      {name:'Customize Tracking Page',value:'3',navColor:false},
      {name:'Plan',value:'4',navColor:false},
    ],
    meal_list:[
      {price:'$ 9/mo.',num:250,state:'enabled',id:'1',oneOrder:'$0.036'},
      {price:'$19/mo.',num:750,state:'enabled',id:'2',oneOrder:'$0.025'},
      {price:'$39/mo.',num:1600,state:'enabled',id:'3',oneOrder:'$0.024'},
    ],
    tabActive:'1',
    alertShow:false,
    alert:false,
    tabVal:"0",
    day:70,
    order:50,
    dayleft:32,
    orderleft:32,
    exceptionnum:0,
    undeliverednum:0,
    visible:false
  }
 }

  changeNav(item,index){
    let nav_list = this.state.nav_list.map(item=>{
      item.navColor = false
      return item
    });
    nav_list[index].navColor=true
    this.setState({tabActive:item.value,nav_list:nav_list,tabVal:"0"})
  }
  changeTabs(val){
    this.setState({tabVal:val},() => {
      this.child.get(this.state.tabVal,1,10)
    });
  }
  setActive(num,val){
    let tabVal = val;
    this.setState({tabActive:num,tabVal:val},() => {
      // console.log(this.child)
      this.changeNav(this.state.nav_list[1],1)
      setTimeout(() => {
        this.changeTabs(val)
        this.child.get(val,1,10)
        this.setState({alertShow:false})
      }, 80);
    })
  }
  showAlert(){
    let alertShow = this.state.alertShow;
    this.setState({alertShow:!alertShow})
  }
  onRef = (ref) => {
    this.child = ref
  }
  getData(){
    fetch.get(`${Url}/trackingList/` + appId + "?shop=" + shop + "&type=5").then((res) => {
      if(res.success){
        // console.log(res.status)
        if( res.status[1] > 0 || res.status[7] > 0){
          this.setState({alert:true,exceptionnum:res.status[1],undeliverednum:res.status[7]})
        }
        
      }
    })
  }
  getRemaining(){
    fetch.get(`${Url}/trackingchargeBoolean/` + appId + "/" + shop).then((res) => {
      if(res.success){
        // console.log(res)
        if(!res.timestamp){
          this.setState({order:0,dayleft:0,orderleft:0})
        }else{
          let rangeDateNum = (res.timestamp - new Date().getTime()) / (1000 * 60 * 60 * 24);
          this.setState({order:res.share_times + res.share_count,dayleft:Math.floor(rangeDateNum),orderleft:res.share_times})
          if(res.timestamp  < new Date().getTime()){
            this.setState({order:res.share_times + res.share_count,dayleft:0,orderleft:res.share_times})
          }
        }
        
      }
    })
  }
  pay(index){
    let id,val,times ;
    if(index === 0){
      val = "9";
      id = [10014];
      times = 250;
    }else if(index === 1){
      val = "19";
      id = [10015];
      times = 750;
    }else{
      val = "39";
      id = [10016];
      times = 1600;
    }
    let params = {
      value:val,
      ids:id,
      monthly:true,
      share_times:times,
    }
    fetch.post(`${Url}/trackingCharge/${appId}/${shop}`,params).then( (res) => {
        // console.log(res)
        window.open(res.url,"_self");
    })
  }
  getCookie(name){
    let arr,reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if( arr = document.cookie.match(reg)){
      return unescape(arr[2]);
    }else{
      return null;
    }
  }
  setCookie(name,value){
    let Days = 30;
    let exp = new Date(); 
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
  }
  Show(){
    let cookie = this.getCookie('isInstructions');
    if(cookie){
      this.setState({visible:false})
    }else{
      this.setState({visible:true})
      this.setCookie('isInstructions','true')
    }
  }

    //初始化查询
    componentWillMount () {
      this.getData();
      this.getRemaining();
      this.Show()
    }
 
    render () {
      const {tabActive}=this.state;

      
    return(
      <div className="tracking">
         {this.state.alertShow ? <div className="tm_alert">
            <p className="te_alert_title">Alert</p>
            <p className="te_alert_content" onClick={() => {this.setActive("2","2");this.setState({alertShow:false})}}><span>Exception Shipments</span> <span><Badge  style={{ background: '#FFB774' }} overflowCount={99} count={this.state.exceptionnum} /><RightOutlined className="tk_alert_icon"/></span></p>
            <p className="te_alert_content1" onClick={() => {this.setActive("2","8");this.setState({alertShow:false})}}><span>Undelivered Shipments</span><span><Badge count={this.state.undeliverednum} overflowCount={99} /><RightOutlined className="tk_alert_icon" /></span> </p>
         </div>:null}
        <div className="tk_header">
          <div className="th_header_logo">
            <img src={"./assets/image/logo.svg"} alt="" style={{borderRadius:"5pxs"}}/>
            <span>Logistics Tracking</span>
          </div>
          <div className="tk_header_nav">
            {this.state.nav_list.map((item,index)=>{
              return <span className="tk_header_nav_nm" key={item.value} style={{color:item.navColor === true ? '#9C63F9':'#666'}} onClick={()=>{this.changeNav(item,index)}}>{item.name}</span>
            })}
            
          </div>
          <div className="tk_header_notice">
            <div className="Instructions" onClick={() => {this.setState({visible:true})}}>Instructions</div>
            {this.state.alert ? <img onClick={()=>{this.showAlert()}} src="./assets/image/bell_active.svg" alt="" className="tk_header_notice"/> : <img className="tk_header_notice" src={"./assets/image/bell.svg"}/> }
          </div>
          
        </div>
        <div className="tk_content">
            {
             tabActive === '1'?
             <Charts chartdata={this.state.chartdata} tabActive={this.state.tabActive} nav_list={this.state.nav_list} setActive={(val,list)=>{
               this.setActive(val,list)
             }}></Charts>
            :tabActive === '2'?
            <Track dayleft={this.state.dayleft}  orderleft={this.state.orderleft} tabVal={this.state.tabVal} setTabs={(val) => {this.changeTabs(val)}} onRef={this.onRef}>

            </Track>
             : tabActive === '3' ?
            <Customize></Customize>
              :<div className="plan">
                <div className="plan_top">
                  <p className="plan_top_title">Current quota</p>
                  <p className="plan_top_small">Current quota of your account.You can recharge anytime based on your needs.</p>
                  <p className="plan_top_title plan_top_title2">Remaining Days: {this.state.dayleft} </p>
                  {/* <span style={{color:'#999'}}>/ {this.state.day}</span> */}
                  {/* <div className="plan_top_progress"><p className="plan_top_progress_bar" style={{width:`calc( ${this.state.dayleft} * 100% / ${this.state.day} )`}}></p></div> */}
                  <p className="plan_top_title plan_top_title2">Remaining Times: {this.state.orderleft} <span style={{color:'#999',marginLeft:"5px"}}> / {this.state.order}</span></p>
                  <div className="plan_top_progress"><p className="plan_top_progress_bar" style={{width:`calc( ${this.state.orderleft} * 100% / ${this.state.order} )`}}></p></div>
                  <p className="plan_top_small">Tip：{this.state.orderleft} left orders can be synced.</p>
                </div>
                <div className="plan_bottom">
                  <h4 className="plan_top_title">Charging scheme</h4>
                  <ul className="plan_meal_list">
                    {
                      this.state.meal_list && this.state.meal_list.map((item,index)=>{
                        return ( <li className="meal_li" key={index}>
                        <span className="meal_li_left"><span className="meal_li_left_price">{item.price}</span> Up to <b>{item.num}</b> orders tracking pre month.</span>
                        <Button className="li_button" type="primary" onClick={() => {this.pay(index)}}>Start using</Button>
                      </li>)
                       
                      })
                    }
                  </ul>
                </div>
              </div>

            }
        </div>
        <Modal 
        visible={this.state.visible}
        title="Instructions"
        width={600}
        className="InstructionsModal"
        onCancel={() => {this.setState({visible:false})}}
        footer={[
          <Button key="submit" type="primary" onClick={() => {this.setState({visible:false})}}>
            OK
          </Button>,
        ]}
        >
          <div className="msg">
            <p>Synchronize orders from the Shopify store to the app:</p>
            <span>1. Enter the "Tracking Number" page</span>
            <span>2. Select orders for the time period you want to synchronize</span>
            <span>3. Click the "Sync Tracking Status" button</span>
            <p>Enable or Disable Customize Tracking Page on your online store:</p>
            <span>1. Enter the "Customize Tracking Page" Page</span>
            <span>2. Click "Display" or "Undisplay"</span>
            <span>3. Click "Save"</span>
            <p>Customize your tracking page:</p>
            <span>1. Enter the "Customize Tracking Page" Page</span>
            <span>2. Modify Progress Bar Color</span>
            <span>3. Show the map or not show the map</span>
            <span>4. Map shows current location or destination location</span>
            <span>5. Change the date and time style</span>
          </div>
        </Modal>
      </div>
      )}
  }

export default Index;
