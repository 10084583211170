
import React from 'react'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import PageRouter from './PageRouter'

class AppRouter extends React.Component {
render() {
return  <Router>
    <Switch>
      <PageRouter></PageRouter>
    </Switch>
  </Router>
}
}
export default AppRouter