import React from 'react';
import * as echarts from 'echarts'
import '../style/tab.less'
import fetch from '../fetch/axios'
import Modal from 'antd/lib/modal/Modal';
let appId = window.id || 429
let shop = window.shop || '10-hz-ts-21-mutao-jingtian.myshopify.com'
let Url = process.env.URL

class Charts extends React.Component {
  constructor(props){
    super(props)
    this.state={
        ordersData:[],
        clicktimesData:[],
        tracknum:[]
    }
  }
  
  createCharts(){
    let myChart = echarts.init(document.getElementById('dashboard_bar'));
    myChart.setOption({
      title: {
        text: 'Orders & Tracking Clicks',
        left: 'center'
    },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    legend: {
        data: ['Orders', 'Click times'],
        //  'Desert', 'Wetland'
       right:'right'
      
    },

    xAxis: [
        {
            type: 'category',
            axisTick: {show: false},
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May',"Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
        }
    ],
    yAxis: [
        {
            type: 'value'
        }
    ],
    series: [
        {
            name: 'Orders',
            type: 'bar',
            barGap: 0,
            emphasis: {
                focus: 'series'
            },
            data: this.state.ordersData,
            color:['#9C63F9'],
            label: {
              show: false,		//开启显示
              position: 'top',	//在上方显示
              textStyle: {	    //数值样式
                  color: '#9C63F9',
                  fontSize: 10
              }
            }
        },
        {
            name: 'Click times',
            type: 'bar',

            emphasis: {
                focus: 'series',
            },
            data: this.state.clicktimesData,
            color:['#6EB7C7'],
            label: {
              show: false,		//开启显示
              position: 'top',	//在上方显示
              textStyle: {	    //数值样式
                  color: '#6EB7C7',
                  fontSize: 10,
                  fontWeight: "bold"
              }
            }
        }
    ]
    })
   }
   createPieCharts(prop){
    let myChart=echarts.init(document.getElementById('dashboard_pie'));
    myChart.on('click',function(params){
      // console.log(prop)
      let nav_list = prop.nav_list.map(item=>{
        item.navColor = false
        return item
      });
      nav_list[1].navColor = true
      prop.setActive('2',(params.data.index).toString())
      // console.log(params.data.index)
    })
    myChart.setOption({
      title: {
        text: 'Tracking number status in last 30 days',
        left: 'center'
    },
      tooltip: {
        trigger: 'item'
    },
    legend: {
        top: '5%',
        right:'right'
    },
    series: [
        {
            // name: '访问来源',
            type: 'pie',
            radius: ['60%', '80%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center',
                
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '40',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false,
            },
            data: [
                {value: this.state.tracknum[2], index:3,name: 'Not Found',itemStyle:{color:"#f15a22"}},
                {value: this.state.tracknum[3], index:4,name: 'Transit',itemStyle:{color:"#F6B06E"}},
                {value: this.state.tracknum[5], index:6,name: 'Pick Up',itemStyle:{color:"#FFDA90"}},
                {value: this.state.tracknum[0], index:1, name: 'Delivered',itemStyle:{color:"#77D1B7"}},
                {value: this.state.tracknum[6], index:7,name: 'Expired',itemStyle:{color:"#85A6EB"}},
                {value: this.state.tracknum[7], index:8,name: 'Undelivered',itemStyle:{color:"#d93a49"}},
                {value: this.state.tracknum[1], index:2,name: 'Exception',itemStyle:{color:"#80C6E1"}},

            ]
        }
    ]
    })
   }

   getDate(){
      fetch.get(`${Url}/trackingList/` + appId + "?shop=" + shop + "&type=5").then((res) => {
        if(res.success){
          // console.log(res)
          this.state.ordersData  = res.orderDate[0].map((ele) => {
            return ele === null ? 0 : ele
          })
          this.state.clicktimesData  = res.orderDate[1].map((ele) => {
            return ele === null ? 0 : ele
          })
          this.state.tracknum  = res.status.map((ele) => {
            return ele === null ? 0 : ele
          })
          this.setState({ordersData:this.state.ordersData,clicktimesData:this.state.clicktimesData,tracknum:this.state.tracknum},() => {
            this.createCharts()
            this.createPieCharts(this.props)
          })
        }
      })
   }
   componentWillMount  (){
    this.getDate()
   }

   componentDidMount () {
    //  console.log(this.props)
}
  render() {
    return  <div className="dashboard">
              <div id="dashboard_bar"></div>
              <div id="dashboard_pie"></div>
            </div> 
  }
}

export default Charts;
