import React from 'react';
import {Tabs,Table,DatePicker,Input,Button,Spin} from 'antd'
import { ClockCircleOutlined,AudioOutlined  } from '@ant-design/icons';
import moment from 'moment';
import fetch from '../fetch/axios'
import '../style/tab.less'

let appId = window.id || 429
let shop = window.shop || '10-hz-ts-21-mutao-jingtian.myshopify.com'
let Url = process.env.URL

class Tables extends React.Component {
  constructor(props){
    super(props)
    this.state={
      tabList:[
        {name:'All',tabVal:'0'},
        {name:'Not Found',tabVal:'3'},
        {name:'Transit',tabVal:'4'},
        {name:'Pick Up',tabVal:'6'},
        {name:'Delivered',tabVal:'1'},
        {name:'Expired',tabVal:'7'},
        {name:'Undelivered',tabVal:'8'},
        {name:'Exception',tabVal:'2'},
      ],
      data:[
        
      ],
      track_number:'',
      start:undefined,
      end:undefined,
      loading:false,
      pagination:{
        pageSize:10,
        current:1
      },
      search:true,

    }
  }
onSearch(value){
    this.setState({loading:true},() => {
      fetch.get(`${Url}/tracking/` + appId + "?" + "shop=" + shop + "&tracking_number=" + value  ).then(res => {
        if(res.success){
          let datalist = [];
          res.data.forEach((ele) => {
            datalist.push({
              key:ele.order_id,
              track_number:ele.tracking_number,
              order:ele.order_id,
              courier:ele.courier,
              point_date:JSON.parse(ele.lon_lat).location[JSON.parse(ele.lon_lat).location.length - 1],
              order_date:this.formatDate(ele.order_date),
              status:ele.status
            })
          })
          this.setState({loading:false,data:datalist},() => {
  
          })
        }
      })
    })
    
    
  }
  changeDate=(val)=>{
    this.setState({start:val[0].format("YYYY/MM/DD HH:mm:ss"),end:val[1].format("YYYY/MM/DD HH:mm:ss")})
    // console.log(val[0].format("YYYY/MM/DD HH:mm:ss"))
    

  }

  syncTrack = () => {
    if(false){
      // 时间

    }else if(false){
      // 次数
    }else{
      this.setState({loading:true})
    //   let params = {
    //   end_time:new Date(this.state.end).getTime(),
    //   start_time:new Date(this.state.start).getTime(),
    // }
    // console.log(params)
    fetch.put(`${Url}/tracking/` + appId + "?" + "shop=" + shop + "&start_time=" + new Date(this.state.start).getTime() + `
    &end_time=` + new Date(this.state.end).getTime() ).then(res => {
      if(res.success){
        this.get("",1,10)
      }
    })
    }
    // console.log(new Date(this.state.start).getTime())
    // console.log(new Date(this.state.end).getTime())
    
  }

  // 时间戳转日期
  formatDate(now) { 
    let date = new Date(parseInt(now));
    let year = date.getFullYear();
    let mon = date.getMonth()+1  >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth()+1);
    let day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
    let hours = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
    let minu = date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
    let sec = date.getSeconds() >= 10 ? date.getSeconds() : "0" + date.getSeconds();
    return year + '-' + mon + '-' + day + ' ' + hours + ':' + minu + ':' + sec;
} 

  get = (status,page,size) => {
    this.setState({loading:true})
    let params = {
      // page:page,
      // status:status,
      // size:size
    }
    fetch.get(`${Url}/tracking/` + appId  + "?" + "shop=" + shop + `&page=${page}&size=${size}&status=${status === 0 ? "": status}`).then(res => {
      // console.log(ressize)
      if(res.success){
        let datalist = [];
        if(res.data.length > 0){
          // console.log(res.data[0])
          res.data.forEach((ele) => {
            datalist.push({
              key:ele.order_id ? ele.order_id : "",
              track_number:ele.tracking_number ? ele.tracking_number : "",
              order:ele.order_id ? ele.order_id : "",
              courier:ele.courier ? ele.courier : "",
              point_date: ele.lon_lat ? JSON.parse(ele.lon_lat).location[JSON.parse(ele.lon_lat).location.length - 1] : "",
              order_date: ele.order_date ? this.formatDate(ele.order_date) : "",
              status:ele.status ? ele.status : ""
            })
          })
        }
        this.setState({loading:false,data:datalist})
      }else{
        this.setState({loading:false})
      }

    })
  }

  componentWillMount () {
    this.props.onRef(this)
    if(this.props.tabVal === "0"){
      this.state.pagination.current = 1;
      this.setState({pagination:this.state.pagination})
      this.get("",1,10)
      // console.log(typeof this.props.dayleft)
    }
    //  console.log("111")
}
  render() {
    const { TabPane } = Tabs;
    const { RangePicker } = DatePicker
    const smileIcon = <ClockCircleOutlined />;
    const {Search}=Input
    const columns=[
      {
        title: 'Tracking Number',
        dataIndex: 'track_number',
      },
      {
        title: 'Order ',
        dataIndex: 'order',
      },
      {
        title: 'Courier',
        dataIndex: 'courier',
      },
      {
        title: 'Last Check Point',
        dataIndex: 'point_date',
      },
      {
        title: 'Order Date',
        dataIndex: 'order_date',
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
    ]
    return  <div className="tables" >
               <Tabs activeKey={this.props.tabVal} onTabClick={(e) => {this.props.setTabs(e);this.state.pagination.current = 1;
      this.setState({pagination:this.state.pagination})}}>
                 {
                   this.state.tabList.map((item,index)=>{
                     return (
                      <TabPane tab={item.name} key={item.tabVal}   >
                        <div className="track_search">
                            <div className="track_search_left">
                              <span>Time：</span>
                              <RangePicker
                           
                                  showTime
                                  suffixIcon={smileIcon}
                                  format="YYYY/MM/DD HH:mm:ss"
                                  onChange={this.changeDate}
                                />
                                <Button style={{marginLeft:'5px'}} type="primary"  disabled={(!this.state.end || !this.state.start || this.state.loading || this.props.dayleft <= 0 || this.state.orderleft <= 0) } onClick={()=>{this.syncTrack()}}>Sync Tracking Status</Button>
                            </div>
                            <div className="track_search_right">
                              <Search   onSearch={(value, event) => {this.props.setTabs("0");if(value !== ""){setTimeout(() => {this.onSearch(value, event)},80)}else{this.get("",1,10)}}}  placeholder="Search tracking number" className="track_input"></Search>
                            </div>
                        </div>
                        {this.state.loading ? <div className="track_search_loag"><Spin size='large'/></div> :
                         <Table id="data"   pagination={this.state.pagination} onChange={(e) => {this.state.pagination.current = e.current;this.setState({pagination:this.state.pagination},() => {this.get(this.props.tabVal,this.state.pagination.current,10)})}} columns={columns} dataSource={this.state.data} rowKey={record=>record.track_number} loading={this.state.loading}></Table>}
                      </TabPane>
                      )
                   })
                 }
                 
                </Tabs>
          </div> 
  }
}

export default Tables;
