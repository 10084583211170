/**
 * @Author: li
 * @Date: 2019
 * @Description: 请求库配置
 */
import axios from 'axios'
// import qs from 'qs'

axios.create({
  baseURL: '',
})
// 添加请求拦截器
axios.interceptors.request.use((config) => {
  // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  // if(config.method === 'post') {
  //   config.data = qs.stringify({
  //       ...config.data
  //   })
  // } 
  let token=localStorage.getItem("token")
  // 在发送请求之前做些什么
  if(token){
    config.headers['Authorization'] = token;
  }
  // config.headers['X-Shopify-Access-Token'] = 'e592c34ee279db33de4a2f5f2da51fe2';
  
  return config;
}, (error) => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
  if (response.data && response.data.user && response.data.user.token) {
    let storage=response.data.user.token;
    localStorage.setItem("token",storage)
    localStorage.setItem("mailbox",response.data.user.mailbox)

  }
 
  return response.data
}, (error) => {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default axios
